.home {
	margin-top: 50px;
}

#names {
	font-size: xxx-large;
}

#and {
	font-size: x-large;
}

.park-watercolor {
	width: 100%
}
