@import '../../common/colors.scss';

.answer {
  margin: 0 15px;
  line-height: 1.8rem;
  color: $std-green;
  font-size: large;
}

.question {
  font-size: xx-large;
  margin: 15px 15px;
}

.question-block {
  margin: 25px 0;
}

@media only screen and (min-width: 800px) {
	.answer {
		margin: 0 175px;
	}
}
