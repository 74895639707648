body {
	margin: 0;
	font-family: 'Libre Baskerville', serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	p {
		font-family: 'Raleway', sans-serif;
		font-weight: 500;
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
