@import '../../common/colors.scss';

.header {
	width: 100%;
	top: 0;
	left: 0;
	background-color: transparent;
}

.nav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	margin: 1rem;
}

.nav__toggle,
.nav__close {
	font-size: 1.5rem;
	color: $std-green;
	cursor: pointer;
}

@media screen and (max-width: 1150px) {
	.nav__menu {
		position: fixed;
		top: 0;
		right: -100%;
		background-color: hsla(0, 0%, 99%, 0.76);
		backdrop-filter: blur(16px);
		-webkit-backdrop-filter: blur(16px);
		width: 80%;
		height: 100%;
		padding: 6rem 3rem 0;
		transition: right 0.4s;
		font-weight: bolder;
	}
}

@media screen and (max-width: 300px) {
	.nav__menu {
		padding: 4rem 1rem 2rem;
	}

	.nav__list {
		row-gap: 1rem;
	}
}

.nav__list {
	display: flex;
	flex-direction: column;
	row-gap: 2.5rem;
	list-style-type: none;
}

.nav__link {
	color: $std-green;
	transition: color 0.4s;
	text-decoration: none;
}

.nav__link:hover {
	border-bottom: 2px solid $std-green;
}

.nav__close {
	position: absolute;
	top: 1rem;
	right: 1.5rem;
}

.show-menu {
	right: 0;
}

.nav__listt {
	display: flex;
	flex-direction: row;
	column-gap: 2.5rem;
}

.nav__menu_mobile {
	position: fixed;
	top: 0;
	right: -100%;
	background-color: hsla(0, 0%, 6%, 0.2);
	backdrop-filter: blur(16px);
	-webkit-backdrop-filter: blur(16px);
	width: 80%;
	height: 100%;
	padding: 6rem 3rem 0;
	transition: right 0.4s;
}

@media screen and (min-width: 1150px) {
	.nav__toggle,
	.nav__close {
		display: none;
	}

	.nav__list {
		flex-direction: row;
		column-gap: 2.5rem;
	}
}
