@import '../../common/colors.scss';

#story-block {
	content: '';
	color: $std-green;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin: 0 5% 50px;
}

#story-block > p,
.photo-group-text > p {
	text-align: center;
	margin: 20px 1px;
	padding: 15px;
	background-color: rgba(255, 255, 255, 0.605);
	border: 2px solid $std-green;
	border-radius: 15px;
	line-height: 1.6;
	width: auto;
	color: $std-green;
}

img {
	border-radius: 25px;
}

.cover-img {
	height: 250px;
}

.bos {
	height: 175px;
}

.photo-group-img {
	height: 175px;
}

.vert {
	height: 300px;
}

.horiz {
	height: 225px;
}

@media only screen and (min-width: 650px) {
	p {
		margin: 20px 25px;
		padding: 15px;
	}

	.photo-group {
		flex-direction: column;
		margin: 10px;
	}

	.cover-img {
		height: 300px;
	}

	.photo-group-img {
		height: 300px;
	}

	.vert {
		height: 350px;
	}
}

@media only screen and (min-width: 1300px) {
	.cover-img {
		margin: 20px 0;
		height: 550px;
	}

	.bos {
		height: 450px;
	}

	.photo-group {
		display: flex;
		align-items: center;
		gap: 0px;
		margin-top: 0;
		margin-bottom: 0;
	}

	.photo-group-text {
		display: flex;
		flex-direction: column;
	}

	.photo-group-img {
		height: 400px;
		margin: 20px 50px 0;
	}

	.vert {
		margin: 20px 0 20px;
	}

	.horiz {
		margin: 20px 0;
	}

	.message {
		text-align: left;
	}
}
